.CTA_background {
  background: url(/assets/STEblog_gradient_globalfooter.svg) 50% / cover
      no-repeat,
    url(/assets/1x/STEblog_gradient_globalfooter-80.jpg) 50% / cover no-repeat;
  background-position-y: bottom;
  background-origin: border-box;
  min-height: 250px;
  max-height: 350px;
  color: #fff;
  position: relative;
}

.CTA_background.CTA_floating {
  background: url(/assets/STEblog_gradient_floatingCTA.svg) 50% / cover
      no-repeat,
    url(/assets/1x/STEblog_gradient_floatingCTA-80.jpg) 50% / cover no-repeat;
}

@media screen and (-o-min-device-pixel-ratio: 5/4),
  screen and (-webkit-min-device-pixel-ratio: 1.25),
  screen and (min-resolution: 1.25dppx) {
  .CTA_background {
    background: url(/assets/STEblog_gradient_globalfooter.svg) 50% / cover
        no-repeat,
      url(/assets/2x/STEblog_gradient_globalfooter@2x-80.jpg) 50% / cover
        no-repeat;
  }
  .CTA_background.CTA_floating {
    background: url(/assets/STEblog_gradient_floatingCTA.svg) 50% / cover
        no-repeat,
      url(/assets/2x/STEblog_gradient_floatingCTA@2x-80.jpg) 50% / cover
        no-repeat;
  }
}

@media (max-width: 1024px) {
  .CTA_background {
    padding: 20px;
  }
  .CTA_background,
  .CTA_background.CTA_floating {
    background: -o-radial-gradient(
      top right,
      circle,
      #fea003,
      #ec5338 25%,
      #d0106d
    );
    background: radial-gradient(
      circle at top right,
      #fea003,
      #ec5338 25%,
      #d0106d
    );
  }
}

.CTA_wrap {
  display: flex;
  display: -ms-flexbox;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  padding: 40px 0;
  position: relative;
  z-index: 1;
}

.CTA_floating .CTA_wrap {
  padding: 40px;
}

.CTA_title {
  font-weight: 700;
  font-size: 32px;
  line-height: 46px;
  margin-bottom: 20px;
  margin-top: 0;
}

.CTA_text {
  font-weight: 300;
  font-size: 22px;
  line-height: 28px;
  max-width: 600px;
  margin: 0;
}

@media (max-width: 1024px) {
  .CTA_text {
    font-size: 18px;
    line-height: 28px;
  }
}

@media (max-width: 1024px) {
  .CTA_background .CTA_wrap button {
    margin-top: 20px;
    width: 100%;
  }
}
